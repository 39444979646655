.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.notification-icon {
  height: 1.5rem;
}
.float-rigth {
  float: right;
}
.front-brand {
  margin-top: 10%;
  margin-left: 20%;
}
.avatar-logo {
  display: none;
}
/* .card-body {
  border-radius: 8px !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2) !important;
} */

@media (max-width: 767px) {
  .front-brand {
    display: none !important;
  }
  .avatar-logo {
    display: block !important;
  }
  .img-mbl {
    width: 30%;
    margin-left: 32%;
  }
  .avatar {
    display: none !important;
  }
}

a.nav-link-main.active {
  color: brown;
}
