.field-label {
    font-weight: bold;
    font-size: 18px;
    color: #333;
    display: block;
    margin-bottom: 10px;
}

.image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.form-control-file {
    margin-bottom: 10px;
}

.image-preview {
    margin-top: 10px;
    text-align: center;
}

.img-thumbnail {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    background-color: #f7f7f7;
}

.upload-button {
    margin-top: 10px;
}
