.tree-card {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.card-header {
  cursor: pointer;
}

.card-children {
  margin-left: 20px;
}

.tree-card.open {
  border: 1px solid #4caf50; /* Change border color when card is open */
}
.b-radius {
  border-radius: 14px;
}
.col-3.main-cd {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  /* border: 1px solid; */
  border-radius: 12px;
}
