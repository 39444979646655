.modal-backdrop {
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    border: 1px solid red; /* Adding red border for danger look */
  }
  
  .modal-body {
    color: red; /* Red text to indicate danger */
    font-weight: bold; /* Bold text for emphasis */
    text-align: center; /* Center the text */
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .modal-footer button {
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .modal-footer button:first-child {
    background: grey; /* Cancel button color */
    color: white;
  }
  
  .modal-footer button:last-child {
    background: red; /* Confirm button color */
    color: white;
  }
  
  /* AddHolidays.css */

.add-button {
    background-color: rgb(204, 82, 69); /* Orange color */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 2px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .add-button:hover {
    background-color: darkorange;
  }
 
  #exampleModalCenter{
    z-index: 1051;
  }
  .del{
    z-index: 1053;
  }
  
    .modal.show {
      display: block;
      background: rgba(0, 0, 0, 0.5);
    }
    /* App.css or similar */

    .modal-content {
      border-radius: 8px;
      padding: 20px;
      width: 600px;
    }
    .modal-header {
      border-bottom: none;
    }
    .modal-footer {
      border-top: none;
    }
    .close {
      font-size: 1.5rem;
      color: #000;
    }
    .table {
      width: 100%;
      margin-bottom: 1rem;
      color: #212529;
      border-collapse: collapse;
    }
    .table th,
    .table td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
    }
    .table thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
    }

    /* modal add */
    /* Modal.css */

.modal-backdrop1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content1 {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  position: relative;
}

.modal-body1 {
  margin-bottom: 20px;
}

.modal-footer1 {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-footer1 button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.modal-footer1 button:hover {
  background: #ddd;
}

.modal-footer1 button:first-child {
  background: #f44336;
  color: white;
}

.modal-footer1 button:last-child {
  background: #4caf50;
  color: white;
}

/* off days css */
.autocomplete-select .MuiAutocomplete-root {
  width: 400px; /* Adjust the width as needed */
}

.autocomplete-select .MuiFormControl-root {
  width: 100%;
}
.label{
  color:black;
}
    
